.part-one {
    background-color: #ecf0f4;
    padding: 40px;
    margin-top: 75px;
    /* max-height: 650px; */
    /* width: 100%; */
}

.part-one .box-text{
    padding: 10% 5%;
}

.part-one h1,h2{
    font-family: 'general-font-bold';
    text-align:start !important;
}

.text-blue{
    color: #002d8b;
    border-color: #002d8b;
}

.box-text .btn{
    margin-top: 25px;
    border-radius: 12px;
}
.btn{
    background-color: #002d8b !important;
}


img{
    max-width: 100%;
}

.part-one.box-img{
    position: static;
}

.part-one .img-back{
    z-index: -10;
    top: 450px;
    left: 650px;
    position:absolute;
}

@media screen and (max-width: 840px){
    .part-one .box-img{
        position: relative;
    }
    
    .part-one .img-back{
        z-index: 1;
        max-height: 100px;
        top: 175px;
        left: -60px;
        position:absolute;
    }
    /* .img-front{
        max-width: 475px;
        max-height: 300px;
        position:absolute;
    } */
}


/* //////////////// PARTE 2 ///////////////////////// */


.part-two {
    padding: 40px;
}

.part-two div{
    text-align: center;
}

.part-two img{
    max-width: 425px;
} 

.part-two .box-text{
    padding: 10% 10% 10% 10%;
}
 
.part-two .box-text h1{
    font-family: 'general-font-bold';
    text-align: start;
} 
.part-two .box-text p{
    margin-top: 20px;
    text-align: justify;
    font-size: 18pt;
} 

@media screen and (max-width: 840px){
    .part-two {
        padding: 40px;
    }
    
    .part-two div{
        text-align: center;
    }
    
    .part-two img{
        max-width: 350px;
    } 
    
    .part-two .box-text{
        padding: 10% 5% 10% 10%;
    }
     
    .part-two .box-text h3{
        font-weight: 700;
        text-align: justify;
    } 
    .part-two .box-text p{
        margin-top: 20px;
        text-align: justify;
        font-size: 14pt;
    } 
}

@media screen and (max-width: 430px){

    .part-two img{
        max-width: 300px;
    } 
    
    .part-two .box-text{
        padding: 10% 10% 10% 10%;
    }
     
}


/* //////////////// PARTE 3 ///////////////////////// */

.part-three {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    background: linear-gradient(to bottom, #d6e3ff, #fff);
}

.part-three h2{
    color: black;
    font-weight: 700;
}

.part-three .items{
    padding-top: 50px;
}

.part-three .items .box{
    background-color: white;
    padding: 25px 5px;
    text-align: start;
    border-radius: 25px;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
    /* margin-right: 130px; */
    width: 225px;
    height: 280px;
}

.part-three .items .box img{
    max-width: 90px;
    margin-left: 160px;
}

.part-three .items .box h4{
    padding-top: 10px;
    padding-left: 10px;
    font-size: 14pt;
    font-family: 'general-font-bold';
}

.part-three .items .box p{
    font-size: 10pt;
    padding: 8px;
}

@media screen and (max-width: 840px){

    .part-three .items .box{
        width: 150px;
    }
    
    .part-three .items .box img{
        max-width: 90px;
        margin-left: 10px;
    }
    
}

@media screen and (max-width: 430px){

    .part-three .items .box{
        width: 350px !important;
        margin: 10px 5px 10px 10px;
    }
    
    .part-three .items .box img{
        max-width: 90px;
        margin-left: 235px;
    }
    
}

@media screen and (max-width: 360px){

    .part-three .items .box{
        width: 300px !important;
        margin: 10px 5px 10px 20px;
    }
    
    .part-three .items .box img{
        max-width: 90px;
        margin-left: 190px;
    }
    
}



/* //////////////// PARTE 4 ///////////////////////// */

.part-four {
    padding: 50px 40px;
}

.part-four .box-text{
    margin-top: 80px;
    padding: 20px 40px;
    text-align: start;
}
.part-four img{
    max-width: 700px;
}

.part-four .btn{
    border-radius: 25px;
    background-color: #029be0 !important;
    border-color: #029be0;
}

@media screen and (max-width: 840px){
    .part-four img{
        padding-top: 60px;
        margin-left: -25px;
        max-width: 400px;
    }
}

@media screen and (max-width: 430px){

    .part-four {
        padding: 10px 40px 20px;
    }

    .part-four img{
        padding-top: 30px;
        max-width: 400px;
        margin-left: 0;
    }
}

@media screen and (max-width: 360px){

    .part-four img{
        max-width: 340px;
    }
}


/* //////////////// PARTE 5 ///////////////////////// */


.part-five {
    background-image: url('background.png');
    background-position: 100%;
    background-repeat: no-repeat;
    background-color: black;
    height: 450px;
}

.part-five .box-text{
    padding: 150px 100px;
    color: white;
    font-family: 'general-font-bold';
}

.part-five .box-text h1 span{
    color:#002d8b;
    font-size: 35pt;
}

.part-five .box-button {
    padding-top: 25%;
    padding-left: 25%;
}

.part-five .box-button .btn{
    border-radius: 25px;
    border-color: #002d8b;
    font-size: 18pt;

}

@media screen and (max-width: 840px){
    .part-five .box-text{
        padding: 150px 0px;
    }
    .part-five .box-button {
        padding-left: 15%;
    }
}

@media screen and (max-width: 430px){
    .part-five .box-text{
        padding: 140px 15px;
    }
    .part-five .box-button {
        margin-top: -200px;
    }
}



/* //////////////// PARTE 6 ///////////////////////// */


.part-six {
    padding: 40px;
}

.part-six div{
    text-align: center;
}

.part-six img{
    max-width: 500px;
} 

.part-six .box-text{
    padding: 10% 50px 10% 50px;
    text-align: right;
}
 
.part-six .box-text h1{
    font-weight: 400;
} 
.part-six .box-text .btn{
    border-radius: 25px;
} 

@media screen and (max-width: 840px){
    .part-six {
        padding: 40px;
    }
    
    .part-six div{
        text-align: center;
    }
    
    .part-six img{
        max-width: 350px;
    } 
    
    .part-six .box-text{
        padding: 10% 5% 10% 10%;
    }
     
    .part-six .box-text h1{
        font-weight: 700;
        text-align: right;
        font-size: 22pt;
    } 
    .part-six .box-text .btn{
        border-radius: 25px;
        font-size: 18pt;
    } 
}

@media screen and (max-width: 430px){

    .part-six img{
        max-width: 300px;
    } 
    
    .part-six .box-text{
        padding: 10% 10% 10% 10%;
    }
     
}


/* //////////////// PARTE 7 ///////////////////////// */

.part-sevent {
    padding: 20px 0;
}
.part-sevent h1{
    margin: 20px 0;
    font-weight: 700;
    text-align: start;
    font-family: 'general-font-bold';
}

@media screen and (max-width: 430px){

    .part-sevent img{
        margin-bottom: 15px;
    } 
    
}

/* //////////////// PARTE 8 ///////////////////////// */

.part-eight {
    background-color: #ecf0f4;
    padding: 35px 25px;
}

.part-eight .body {
    background-image: url('background-eight.png');
    background-repeat: no-repeat;
    background-position: bottom;
    border-radius: 25px;
}

.part-eight .body .box-text{
    padding: 150px 100px;
    font-weight: 400;
}
.part-eight .body .box-text h1{
    font-weight: 400 !important;
}
.part-eight .body .box-img{
    text-align: center;
}

.part-eight .body .btn{
    border-color: #002d8b;
    border-radius: 25px;
}

@media screen and (max-width: 840px){

    .part-eight .body .box-text{
        padding: 130px 50px;
    }
    .part-eight .body .box-text h1{
        font-size: 20pt;
    }
    
}

@media screen and (max-width: 430px){

    .part-eight .body .box-text{
        padding: 50px 50px;
    }
    .part-eight .body .box-text h1{
        font-size: 20pt;
    }
    
}

/* ////////////////////////////////////// FOOTER ////////////////////////////////////// */

.footer {
    background-color: #ecf0f4;;
    padding-top: 10px;
    padding-bottom: 100px;
    list-style: none;
    color: #002d8b;
}

.footer hr{
    padding-bottom: 50px;
    margin-left: 25px;
    margin-right: 25px;
    border-top: 3px solid #002d8b;
}

.footer ul {
    list-style: none;
    padding: 0;
}

.footer ul li{
    list-style: none;
    font-weight: 450;
    padding-bottom: 5px;
}
.footer ul li a{
    text-decoration: none;
    color: #002d8b;
}

@media screen and (max-width: 430px){

    .footer {
        padding: 10px 30px 100px 30px;
    }
    .footer .logo {
        text-align: center;
        padding-bottom: 75px;
    }
     
}


