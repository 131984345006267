
@font-face {
  font-family: 'general-font';
  src: url('font/HomepageBaukasten.ttf') format('truetype'),
       url('font/HomepageBaukasten.otf') format('truetype'),
}

@font-face {
  font-family: 'general-font-bold';
  src: url('font/HomepageBaukasten-Bold.ttf') format('truetype'),
        url('font/HomepageBaukasten-Bold.woff') format('woff'),
        url('font/HomepageBaukasten-Bold.woff2') format('woff2'),
}

body{
  background-color: white !important;
  font-family: 'general-font' !important;
}

.upIcon a{
  border-radius: 25px;
  position: fixed;
  z-index: 1000;
  bottom: 20px;
  right: 20px;
  height: 50px;
  width: 50px;
  background-color: #002d8b;
  color: white;
  padding: 12px 12px;
}