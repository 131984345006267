.contenedorinicial{
    margin-top: 150px;
    color: #00298f;
}

.ag_title{
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 50px;
    font-family: 'general-font-bold';
}


.footer{
    margin-bottom: 10px;
}

.ag_parrafo{
    color: #616161;
}

.section-img{
    width: 60%;
}

.ag_container{
    text-align: center;
}